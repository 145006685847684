import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="text-center text-lg-start text-dark ">
      <section class="d-flex justify-content-between p-4 text-white">
        <div class="me-5">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="" class="text-white me-4">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="" class="text-white me-4">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="" class="text-white me-4">
            <i class="fab fa-google"></i>
          </a>
          <a href="" class="text-white me-4">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="" class="text-white me-4">
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="" class="text-white me-4">
            <i class="fab fa-github"></i>
          </a>
        </div>
      </section>

      <section class="">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4  d-flex  align-items-start flex-column">
              <h6 class="text-uppercase fw-bold">شركة الأفضل</h6>
              <hr class="mb-4 mt-0 d-inline-block mx-auto" />
              <p className="">
                نحن مكتب الآفضل وجهتك المثالية لتسديد قروضك البنكية وسداد
                المتعثرات في سمة وسداد إيقاف الخدمات وتحويل الراتب من بنك إلي
                آخر واستخراج قرض جديد باعلي تمويل يصل الي 36 راتب
                <br /> استخراج قرض عقاري بدون فوائد وتصفية مبلغ عالي للعميل
                بالإضافة الي وحدة سكنية جاهزة كاملة التشطيب
                <br /> نسدد قروض المدنيين والعسكريين
                <br /> نسدد قروض القطاع الخاص
                <br /> نستخرج تمويل من جميع البنوك
                <br /> ونستخرج تمويل من بنك التنمية الاجتماعية
                <br /> بنك التسليف نتشرف بخدمتكم
              </p>
            </div>

            {/**  <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4  d-flex  align-items-start flex-column">
              <h6 class="text-uppercase fw-bold">Products</h6>
              <hr class="mb-4 mt-0 d-inline-block mx-auto" />
              <p>
                <a href="#!" class="text-dark">
                  MDBootstrap
                </a>
              </p>
              <p>
                <a href="#!" class="text-dark">
                  MDWordPress
                </a>
              </p>
              <p>
                <a href="#!" class="text-dark">
                  BrandFlow
                </a>
              </p>
              <p>
                <a href="#!" class="text-dark">
                  Bootstrap Angular
                </a>
              </p>
            </div> */}

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4   d-flex  align-items-start flex-column">
              <h6 class="text-uppercase fw-bold">التواصل الإجتماعى</h6>
              <hr class="mb-4 mt-0 d-inline-block mx-auto" />
              <p>
                <Link
                  to="https://www.snapchat.com/add/afdalsadad?share_id=pI74hb95K4Q&locale=ar-SA"
                  class="text-dark"
                  target="_blank"
                >
                  سناب شات
                </Link>
              </p>
              <p>
                <Link
                  to="https://www.tiktok.com/@alafdal2024?_t=8o9cpmSdPgm&_r=1"
                  class="text-dark"
                  target="_blank"
                >
                  تيك توك
                </Link>
              </p>
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 d-flex  align-items-start flex-column ">
              <h6 class="text-uppercase fw-bold">Contact</h6>
              <hr class="mb-4 mt-0 d-inline-block mx-auto" />
              <p>
                <i class="fas fa-home mr-3"></i> طريق المطار جازان السعودية
              </p>
              <Link to="mailto: alafdal2024@gmail.com" className="text-black">
                <p>
                  <i class="fas fa-envelope mr-3"></i> alafdal2024@gmail.com
                </p>
              </Link>
              <Link to="tel: +966 057 313 2077" className="text-black">
                {" "}
                <p>
                  <i class="fas fa-phone mr-3"></i> +966 057 313 2077
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div class="text-center p-3 footerBottom">
        © 2024 Copyright:
        <Link
          class="text-dark"
          to="https://www.linkedin.com/in/omar-ashraf-othman-15b87a1a8/"
          target="_blank"
        >
          Omar Othman
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
