import React from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/images/mony-removebg-preview.png";

function Home() {
  return (
    <div>
      <section class="banner" id="home">
        <div class="container ">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              {" "}
              <div class="infos col-12 col-lg-6">
                <h6 class="subtitle">أهلا بكم في </h6>
                <h1 class="title my-3">سداد قروض جازان </h1>
                <p>مؤسسه أفضل سداد لتحصيل الديون</p>

                <div class="buttons pt-3">
                  <Link
                    class="btn btn-blue  rounded py-3 ms-3 mb-2"
                    to="https://wa.link/j09mfc"
                    target="_blank"
                  >
                    اضغط هنا للتواصل عن طريق الواتساب
                  </Link>
                  <Link
                    class="btn btn-dark btnDarkBlue rounded py-3"
                    to="tel:0573132077"
                  >
                    اضغط هنا لتتصل بنا مباشرة
                  </Link>
                </div>

                {/**     <div class="socials mt-4">
              <a class="social-item" href="javascript:void(0)">
                <i class="fa-brands fa-facebook"></i>
              </a>
              <a class="social-item" href="javascript:void(0)">
                <i class="ti-google"></i>
              </a>
              <a class="social-item" href="javascript:void(0)">
                <i class="ti-github"></i>
              </a>
              <a class="social-item" href="javascript:void(0)">
                <i class="ti-twitter"></i>
              </a>
            </div> */}
              </div>
              <div class="img-holder col-12 col-lg-6">
                <img src={banner} alt="banner" className="w-100" />
              </div>
            </div>
          </div>
        </div>

        {/** <div class="widget">
          <div class="widget-item">
            <h2>124</h2>
            <p>Happy Clients</p>
          </div>
          <div class="widget-item">
            <h2>456</h2>
            <p>Project Completed</p>
          </div>
          <div class="widget-item">
            <h2>789</h2>
            <p>Awards Won</p>
          </div>
        </div> */}
      </section>
      <section className="mapSection">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3817.5508675821884!2d42.57130932480044!3d16.898097983907974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1607e2b879c26a7b%3A0x7061b7d63d849168!2z2LfYsdmK2YIg2KfZhNmF2LfYp9ix2Iwg2KzYp9iy2KfZhiDYp9mE2LPYudmI2K_Zitip!5e0!3m2!1sar!2seg!4v1721223721048!5m2!1sar!2seg"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </div>
  );
}

export default Home;
